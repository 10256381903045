<template>
<div class="row">
    <div class="col-md-122">
      
            <div class="d-flex flex-row bd-highlight mb-4 border-bottom">
                <router-link :to="{ name: 'datosestudiantes' , params: { id: idpersona } }" class="p-2 pe-3 opcionesmenu btn  ">Datos Personales</router-link>
                <router-link :to="{ name: 'historialmatricula' , params: { id: idpersona } }" class="p-2 pe-3 opcionesmenu btn  ">Matrícula</router-link>
                <!-- <router-link :to="{ name: 'historialmatricula' , params: { id: idpersona } }" class="p-2 pe-3 opcionesmenu btn  ">Acudientes</router-link> -->
                <router-link :to="{ name: 'EncuestasPersona' , params: { id: idpersona } }" class="p-2 pe-3 opcionesmenu btn  ">Encuestas</router-link>
            </div> 

            <router-view></router-view>

         
      </div>
</div>
</template>
<script>
import {ref} from 'vue'


export default {
    components:{
        // DPersonales,
        // Hmatricula,
        // Encuestasestudiantes
    },
    props: ['id','nombre'],
    setup(props) {
        
        const idpersona = ref(props.id)
    
        return {
            idpersona
        }
    } 
}
</script>
<style scoped>

.opcionesmenu {
      border-bottom: 2px solid rgb(255, 255, 255);
}

.opcionesmenu:hover {
      border-bottom: 2px solid rgb(47, 38, 130);
      cursor:pointer; cursor: hand
}
.opcionesmenu:active {
      border-bottom: 2px solid rgb(47, 38, 130);
      background-color: rgb(232, 232, 232)
}

 

</style>
 