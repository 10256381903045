<template>
<div class="row">
  <div class="col-md-12">
        
         <div class="d-flex position-relative">
          <img :src="imglocal+'/imagenes/ico/matriculas.svg'" class="me-3"  style="width: 40px; height: 40px;" alt="">
          <div class="media-body">
            <span class="titulosg h6">Matrículas Académica</span> <br>
            <span class="text-secondary"> Historial de matrículas del estudiante  </span>
          </div>
        </div> 

      <div class="my-4">
            <router-link :to="{ path: '/cematricula/'+idestudiante+'/nueva/matricula', param: {id: idestudiante}}" class="btn btn-sm btn-primary"> <i class="fas fa-plus me-2"></i> Nueva Matrícula</router-link>
      </div>

      <div class="mt-3 alert alert-warning" v-if="historial == null">
        <i class="fas fa-exclamation-circle me-2"></i> El estudiante no registra matrículas en la institución, para registrar presione el boton + Nueva Matrícula
      </div>

      <div class="card p-3" v-if="historial !== null">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr >
                <td class="text-center">N°</td>
                <td>Vigencia</td>
                <td>Grado</td>
                <td>Jornada</td>
                <td>Sede</td>
                <td>Estado</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in historial" :key="item.idacamatricula">
                <td class="align-middle text-end">{{ ('00' + item.numeromatricula).slice (-4)}} </td>
                <td class="align-middle text-center titulosg">{{item.vigencia}}</td>
                <td class="align-middle"> {{ item.nombregradogrupo  }} </td>
                <td class="align-middle"> {{ item.jornada  }} </td>
                <td class="align-middle"> {{ item.nombreempresasede  }} </td>
                <td class="align-middle">
                  <span class="badge bg-secondary" v-if="item.estadomatricula==0"> Cancelada </span>     
                  <span class="badge bg-success" v-if="item.estadomatricula==1"> Matrícula Activa </span>    
                  <span class="badge bg-secondary" v-if="item.estadomatricula==2"> Aprobado </span>    
                  <span class="badge bg-secondary" v-if="item.estadomatricula==3"> Promovido </span>  
                  <span class="badge bg-secondary" v-if="item.estadomatricula==4"> Repitente </span>    
                  <span class="badge bg-danger" v-if="item.estadomatricula==5"> Reprobado </span>  
                </td>
                <td class="align-middle text-end">
                    <button  data-bs-toggle="modal" @click="VerDetalleMatricula(item.idacamatricula)" data-bs-target="#exampleModal" title="Ver" class="btn btn-sm btn-outline-primary me-2"> <i class="fas fa-eye"></i>  </button>
                    <router-link title="Imprimir" :to="{ name: 'Matriculaprint', params:{id:item.idacamatricula}}" class="btn btn-sm btn-outline-primary me-2"> <i class="fas fa-print"></i>  </router-link>
                    <router-link title="Editar" :to="{ path: '/cematricula/'+idestudiante+'/editar/'+item.idacamatricula, param: {id: idestudiante}}" class="btn btn-sm btn-outline-primary me-2">  <i class="fas fa-pencil-alt"></i>  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
          
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-body p-5">
              
              
              <span class="h4 titulosg">Matrícula N° {{ ('00' + vermatricula.numeromatricula).slice (-4)}}   </span> <br>
              Estado de la Matrícula: 
              <span class="badge bg-secondary" v-if="vermatricula.estadomatricula==0"> Cancelada </span>     
              <span class="badge bg-success" v-if="vermatricula.estadomatricula==1"> Matrícula Activa </span>    
              <span class="badge bg-secondary" v-if="vermatricula.estadomatricula==2"> Aprobado </span>    
              <span class="badge bg-secondary" v-if="vermatricula.estadomatricula==3"> Promovido </span>  
              <span class="badge bg-secondary" v-if="vermatricula.estadomatricula==4"> Repitente </span>    
              <span class="badge bg-danger" v-if="vermatricula.estadomatricula==5"> Reprobado </span>  
              
              <div class="mt-3 mb-4">
                <i class="far fa-calendar-alt"></i>  <strong class="me-3">Fecha de Registro:</strong>  {{ FormatoFecha(vermatricula.created_at)}} <span class="p-3">/</span>
                <i class="far fa-clock"></i> <strong>Hora:</strong>  {{ FormatoHora(vermatricula.created_at)}}                
              </div>


              <table class="table table-borderless table-striped mt-3">
                <tbody>
                  <tr>
                    <td style="width: 25%">Vigencia:</td>
                    <td>{{vermatricula.vigencia}}</td>
                  </tr>
                  <tr>
                    <td>Grado:</td>
                    <td>{{vermatricula.nombregradogrupo}}</td>
                  </tr>
                  <tr>
                    <td>Jornada:</td>
                    <td>{{vermatricula.jornada}}</td>
                  </tr>
                  <tr>
                    <td>Sede:</td>
                    <td>{{vermatricula.nombreempresasede}}</td>
                  </tr>
                </tbody>
              </table>

              <strong>Procedencia</strong>
              <table class="mt-2 table table-borderless table-striped">
                <tbody>
                  <tr>
                    <td style="width: 25%">Institución de Procedencia:</td>
                    <td>{{vermatricula.nombreprocedencia}}</td>
                  </tr>
                  <tr>
                    <td>Ciudad:</td>
                    <td>{{vermatricula.nombreciudad}}</td>
                  </tr>
                  <tr>
                    <td>Sector:</td>
                    <td>
                      <span v-if="vermatricula.procedencia==1">Oficial</span>
                      <span v-if="vermatricula.procedencia==2">No Oficial</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="card card-body mb-1">
                <strong>Observaciones:</strong> <br>
                {{vermatricula.observacion}}  
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="bg-light p-2 text-secondary">
                    Última Actualización: <i class="far fa-calendar-alt ms-3"></i>  <strong>Fecha:</strong>  {{ FormatoFecha(vermatricula.updated_at)}} 
                    <i class="far fa-clock ms-3"></i> <strong>Hora:</strong>  {{ FormatoHora(vermatricula.updated_at)}}
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-primary mt-2 float-end" data-bs-dismiss="modal">Cerrar</button>

            </div>
      
          </div>
        </div>
      </div>

  </div>
</div>
</template>

<script>

    import axios from 'axios'
    import moment from 'moment'

    import { ref, onMounted } from 'vue'

    export default {
        props: ['id'],
        components:{
           
        },
        setup(props) {

          const idestudiante = ref(props.id)

            const historial = ref([]) 
            const idmatriculaeditar = ref() 

            const vermatricula = ref([])

            const consultarhistorico = () => {

                axios.get('api/matriculasxestudiante/'+props.id).then(response => {
                    historial.value = response.data
                }).catch(error => {
                    console.log(error)
                })

            }

            const VerDetalleMatricula = (idmatricula) => {                
                vermatricula.value = historial.value.find( value => value.idacamatricula == idmatricula)
            }

            const FormatoFecha = (date) => {
                return moment(date).format('DD [de] MMMM [de] YYYY');
            }

            const FormatoHora = (date) => {
                return moment(date).format('h:mm:ss a');
            }


            onMounted(() => {
                consultarhistorico();
            })

            return { 
                  idestudiante, 
                  historial, 
                  FormatoFecha, 
                  idmatriculaeditar, 
                  FormatoHora,
                  vermatricula,

                  //funciones
                  VerDetalleMatricula
            }

        }

    }
</script>

<style>

</style>